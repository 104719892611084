import axios from "axios";
import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { getFetcher } from "../Utils/AxiosWrapper";
export default function Price({id}) {
    const [price, setPrice] = useState("");
    const { data, error } = useSWR("/api/Products/"+id, getFetcher);
  return (
      <>
      {data &&       <div style={{color: `#e37100`, fontWeight: `bold`, fontSize: `16px`}}>
           Price: ${data.result.sync_variants[0].retail_price}
        </div>}
      </>
  )
}
