import axios from "axios";
import React, { useState, useEffect } from "react";
import ShoppingSection from "./ShoppingSection";
import useSWR from "swr";
import { getFetcher } from "../Utils/AxiosWrapper";
import useCurrentWidth from "./useCurrentWidth";
export default function GetItems({search, title, shoes, onesie, select, ignore}) {
    const [json, setJson] = useState();
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get("search") ? urlParams.get("search").replace("-undefined","") : search;
    const [itemTitle, setItemTitle] = useState("");
    const [grouping, setGrouping] = useState([]);
    const { data, error } = useSWR("/api/Products?search="+page+"&offset="+offset+"&limit=96", getFetcher);
    console.log("data", data);
    const scrollTop = function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  useEffect(() => {
    if (data)
    {
        let items = [];
        let current = [].concat(grouping);
        if (data.paging)
        {
          setTotal(data.paging.total);
        }
        data.result.forEach((item, index) => {
            let group = item.name.split("-");
            if (group && group.length > 2 && !select)
            {
              group = group.slice(0,-1).join('-');
              let found = false;
              current.forEach((cat) => {
                if (group.trim().toLowerCase() === cat.trim().toLowerCase())
                {
                  found = true;
                }
              })
              if (!found)
              {
                console.log("title",title);
                current.push(group);
                items.push([
                  item.thumbnail_url,
                  "https://strongerthanmed.s3.us-west-2.amazonaws.com/"+item.external_id+"/Back.png",
                  item.name,
                  true ? "" : title.includes("Custom Search") ? "" : shoes ? title.includes("Men's") ? "Sizes: 5, 5.5, 6, 6.5,  ..., 13" : "Sizes: 5, 5.5, 6, 6.5,  ..., 12" : onesie ? "Sizes: 3-6m, 6-12m, 12-18m, 18-24m" : "Sizes: XS, S, M, L, XL, 2XL, 3XL",
                  "/itemdetail?id="+item.id+"&exid="+item.external_id,
                  item.id
              ]);
              }
            }
            else{
              if (select)
              {
                setItemTitle(item.name.split(":")[0])
              }
              items.push([
                item.thumbnail_url,
                "https://strongerthanmed.s3.us-west-2.amazonaws.com/"+item.external_id+"/Back.png",
                item.name,
                true ? "" : shoes ? title.includes("Men's") ? "Sizes: 5, 5.5, 6, 6.5,  ..., 13" : "Sizes: 5, 5.5, 6, 6.5,  ..., 12" : onesie ? "Sizes: 3-6m, 6-12m, 12-18m, 18-24m" : "Sizes: XS, S, M, L, XL, 2XL, 3XL",
                "/itemdetail?id="+item.id+"&exid="+item.external_id,
                item.id
            ]);
            }
        })
        setGrouping(current);
        setJson({items: [items]});
    }
  },[data])

  const width = useCurrentWidth();
  return (
      <>
      {width > 992 && <div style={{width: `100vw`}}><div style={{position: `absolute`, right: `13vw`, top: `150px`, zIndex: `2000`}}>{offset > 0 && <button onClick={() => {scrollTop(); setOffset(offset-96); setGrouping([]);}} style={{marginRight: `30px`, border: `solid 1px white`, cursor: `pointer`}}>Previous</button>}
        {((total-offset) > 96) &&<button style={{border: `solid 1px white`, cursor: `pointer`}} onClick={() => {scrollTop(); setOffset(offset+96)}}>Next</button>}</div></div>}
      <div style={{paddingLeft: `25px`}}>
           {json && <ShoppingSection
          title={select ? itemTitle : title}
          images={json.items}
          group={search || select ? false : true}
          select={select ? false : true}
          onClick={() => {scrollTop(); setOffset(offset-96);}}
        />}
        <div style={{display: `flex`, justifyContent: `flex-end`, alignItems: `center`, marginRight: `13vw`}}>{offset > 0 && <button onClick={() => {scrollTop(); setOffset(offset-96); setGrouping([]);}} style={{marginRight: `30px`, border: `solid 1px white`,  cursor: `pointer`}}>Previous</button>}
        {((total-offset) > 96) &&<button style={{border: `solid 1px white`, cursor: `pointer`}} onClick={() => {scrollTop(); setOffset(offset+96)}}>Next</button>}</div>
        </div>
      </>
  )
}
