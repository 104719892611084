import React, { useState, useEffect } from "react";
import Price from "./price"
import useCurrentWidth from "./useCurrentWidth";

export default function ShoppingSection({title = "", images = {}, group = false, select = false, onClick= () => {}}) {
  const [isRealMobile, setIsRealMobile] = useState(false);
  const width = useCurrentWidth();
  useEffect(() => {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
      setIsRealMobile(true);
    }
    if ((window.innerWidth < 1100) && (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)))
    {
      setIsRealMobile(true);
    }
    if (images && images[0].length === 1)
    {
      window.location.href = images[0][0][4]+"&found="+false;
    }
  }, [images])



  function pictureHover(e, image) {
    const imageElement = e.target;
    imageElement.src = image;
  }

  function trimIt(item)
  {
    if(item)
    {
      return "*"+item.trim();
    }
    else{
      return item;
    }
  }

    return (
      <div style={{ display: `block` }}>
          <div>
          <div
            className={"row imgPadding"}
            style={{ marginRight: `0px` }}
          >
            <h1
              className={(isRealMobile || width < 767) ? "col-12 bold titlePadding" : select ? "col-12 bold titlePadding" : "col-12 bold"}
              style={{ fontSize: (isRealMobile || width < 767) ? `35px` : `50px`, 
               color: `#e37100`, marginTop: `25px`, marginBottom: `0px` }}
            >
              {title}
            </h1>
            {(!select && images && images.length > 0) && 
            <h2 className={"col-12 bold"} 
              style={{ fontSize: `30px`, color: `#2e10cf` }}><span role="button" tabIndex="0"
              onKeyUp={(e) => {if(e.key === "Enter"){
                if(images[0][0]){window.location.href =
                  "/search?search="+images[0][0][2].split(": ")[1].split("-")[0]}
              }}}
              onClick={() => {if(images[0][0]){window.location.href =
                    "/search?search="+images[0][0][2].split(": ")[1].split("-")[0]} }} className="boldHoverOrange">{images[0][0] && images[0][0][2].split(": ")[1].split("-")[0]}
              </span>
                    <span role="button" tabIndex="0"
              onKeyUp={(e) => {if(e.key === "Enter"){
                if(images[0][0]){window.location.href =
                  "/search?search="+images[0][0][2].split(": ")[1].split("-")[1]}
              }}}
              onClick={() => {if(images[0][0]){window.location.href =
                    "/search?search="+images[0][0][2].split(": ")[1].split("-")[1]} }} className="boldHoverOrange">{images[0][0] && images[0][0][2].split(": ")[1].split("-")[1]}</span></h2>}
            <div  className= "col-12" style={{margin: "25px 0px", display: `flex`}} ><div className="bold" style={{fontSize: `22px`, marginRight: `3px`}}>*</div>
            <div>Links to associated category</div> </div>
            {images.map((shirts, index) =>
              shirts.map((shirt,i) => (
                <div
                  key={index+"index"+i}
                  className={(isRealMobile || width < 767) ? "col-sm-6 col-6 text-center px-0" : "col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center"}
                >
                  <a
                    onMouseOver={isRealMobile ? undefined : event => pictureHover(event, shirt[1])}
                    onMouseOut={isRealMobile ? undefined : event => pictureHover(event, shirt[0])}
                    rel="noopener noreferrer"
                    id={"img"+i}
                    style={{display: `block`}}
                    href={select ? "/itemselection?search="+shirt[2].split("-").slice(0,-1).join('-') : shirt[4]+"&found="+true}
                  >
                    <img
                      style={{ width: (isRealMobile || width < 767) ? "25vw" : "20vw", maxWidth: "235px", marginBottom: (title.includes("Duffle") || (group && shirt[2].split(": ")[0].includes("Duffle"))) ? `0px` : `10px` }}
                      src={shirt[0]}
                      alt={shirt[5]}
                      onError={isRealMobile ? undefined : (e) => {
                        e.target.onerror = null;  
                        let test = document.getElementById("img"+i); 
                        test.style.display="none";
                        test = document.getElementById("img2"+i);
                        test.style.display="block";
                      
                      }}
                    />
                  </a>
                  <a
                    onMouseOver={isRealMobile ? undefined : event => pictureHover(event, shirt[0])}
                    onMouseOut={isRealMobile ? undefined : event => pictureHover(event, shirt[0])}
                    rel="noopener noreferrer"
                    style={{display: `none`}}
                    id={"img2"+i}
                    href={select ? "/itemselection?search="+shirt[2].split("-").slice(0,-1).join('-') : shirt[4]+"&found="+false}
                  >
                    <img
                      style={{ width: "25vw", maxWidth: "235px", marginBottom: (title.includes("Duffle") || (group && shirt[2].split(": ")[0].includes("Duffle"))) ? `0px` : `10px`  }}
                      src={shirt[0]}
                      alt={shirt[5]}
                    />
                  </a>
                  {group ? <div
                    className={"bold boldBlueTitleSmall"}
                    style={{ fontSize: "21px", textAlign: "inherit"}}
                  >
                    <div style={{ color: `#e37100`}}>{shirt[2].split(": ")[0]}</div>
                    <div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                      "/search?search="+shirt[2].split(": ")[1].split("-")[0]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[0] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[0])}</div>
                    <div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                      "/search?search="+shirt[2].split(": ")[1].split("-")[1]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[1] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[1])}</div>
                    {(!select && images && images.length > 0) && 
                    <div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[2]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[2] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[2])}</div>}
                  </div> :
                  <div
                    className={"bold boldBlueTitleSmall"}
                    style={{ fontSize: "21px", textAlign: "inherit" }}
                  >
                    {select && <><div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[0]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[0] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[0])}</div>
                    <div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[1]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[1] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[1])}</div></>}
                    {(!select && images && images.length > 0) && 
                    <div 
                    role="button"
                    tabIndex="0"
                    onKeyUp={(e) => {if(e.key === "Enter"){
                      window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[2]
                    }}}
                    onClick={() => {window.location.href =
                    "/search?search="+shirt[2].split(": ")[1].split("-")[2] }} className="boldHoverOrange">{trimIt(shirt[2].split(": ")[1].split("-")[2])}</div>}
                  </div>}
                  <div
                    className="text-center"
                    style={{
                      fontSize: "14.5px",
                      paddingBottom: "5vw",
                      textAlign: "inherit",
                      color: `black`,
                      marginBottom: `1rem`,
                    }}
                  >
                    {shirt[3]}
                    <Price id={shirt[5]}/>
                  </div>
                </div>
              ))
              
            )}
            </div>
          </div>
      </div>
    );
}
