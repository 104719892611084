import axios from "axios";

let Axios = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 60000,
});

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export function Fetcher(method, url, data = null,) {
  return Axios.request({
    method,
    url,
    data,
    responseType: "json"
  });
}

export const getFetcher = (url) => Axios.get(url).then((res) => res.data);

