import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Totes() {
  return (
    <Layout>
      <GetItems search="Tote" title="Totes" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./accessories/totes'} title="STM Quality Totes: Positivity and Awareness" description={"Shopping for totes to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)